import React from 'react'
import SafachLogo from '../Components/Assets/Images/safach-web.png'
import HumHaiLogo from './Assets/Images/hum-hai-white-web.png'

function Logobar() {
  return (
    <>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-6'>
                    <img src={SafachLogo} alt='Safach Logo'/>
                </div>
                <div className='col-md-6 right'>
                    <img src={HumHaiLogo} alt='Hum Hai Logo'/>
                </div>
            </div>
        </div>
    </>
  )
}

export default Logobar