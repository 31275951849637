import React from 'react'
import Logobar from './Logobar'
import { Link } from 'react-router-dom'
import ChupPoster from './Assets/Images/film-screenings/chup.png'
import PadmanPoster from './Assets/Images/film-screenings/padman.png'
import GumshudaPoster from './Assets/Images/film-screenings/gumshuda.png'
import HoMannJahaan from './Assets/Images/film-screenings/ho-mann-jahaan.png'
import Workshop1 from './Assets/Images/workshops/workshop-1.png'
import Workshop2 from './Assets/Images/workshops/workshop-2.png'
import Workshop3 from './Assets/Images/workshops/workshop-3.png'
import Workshop4 from './Assets/Images/workshops/workshop-4.png'
import Workshop5 from './Assets/Images/workshops/workshop-5.png'
import Workshop6 from './Assets/Images/workshops/workshop-6.png'
import Acting1 from './Assets/Images/acting/acting-1.png'
import Acting2 from './Assets/Images/acting/acting-2.png'
import SaurabhShukla from './Assets/Images/celebs/saurabh-shukla.png'
import MukeshChhabra from './Assets/Images/celebs/mukesh-chhabra.png'
import RajatKapoor from './Assets/Images/celebs/rajat-kapoor.png'
import AnuragBasu from './Assets/Images/celebs/anurag-basu.png'
import MadhurBhandarkar from './Assets/Images/celebs/madhur-bhandarkar.png'
import RajatKapur from './Assets/Images/celebs/rajat-kapur.png'
import Panel1 from './Assets/Images/panels/panel-1.png'
import Panel2 from './Assets/Images/panels/panel-2.png'
import Concert from './Assets/Images/concert.png'
import FilmBazaar1 from './Assets/Images/film-bazaar/film-bazaar-1.png'
import FilmBazaar2 from './Assets/Images/film-bazaar/film-bazaar-2.png'
import FoodCourt1 from './Assets/Images/food-court/food-court-1.png'
import FoodCourt2 from './Assets/Images/food-court/food-court-2.png'
import GamingZone from './Assets/Images/gaming.png'
import OpenMic from './Assets/Images/open-mic.png'
import Selfie from './Assets/Images/selfie.png'
import { MDBBtn } from 'mdb-react-ui-kit';

function Home() {
  return (
    <div className='background'>
      <Logobar/>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center'>
            <p className='display-6'>
              FILM SCREENINGS
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 text-center my-2'>
            <img src={ChupPoster} alt='Chup Poster' className='img-fluid'/>
          </div>
          <div className='col-md-6 text-center my-2'>
            <img src={PadmanPoster} alt='Padman Poster' className='img-fluid'/>
          </div>
          <div className='col-md-6 text-center my-2'>
            <img src={GumshudaPoster} alt='Gumshuda Poster' className='img-fluid'/>
          </div>
          <div className='col-md-6 text-center my-2'>
            <img src={HoMannJahaan} alt='Ho Mann Jahaan Poster' className='img-fluid'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Screens good quality curated films</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              WORKSHOPS & MASTERCLASSES
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={Workshop1} alt='Workshop 1'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={Workshop2} alt='Workshop 2'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={Workshop3} alt='Workshop 3'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={Workshop4} alt='Workshop 4'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={Workshop5} alt='Workshop 5'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={Workshop6} alt='Workshop 6'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Workshops & Masterclasses on - 'Script Writing, Narrative Development' 'Filmmaking - Project Development, Commercialisation, Understanding the Business of Films.'</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              ACTING COMPETETION
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 text-center my-2'>
            <img className='img-fluid' src={Acting1} alt='Acting 1'/>
          </div>
          <div className='col-md-6 text-center my-2'>
            <img className='img-fluid' src={Acting2} alt='Acting 2'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Aspiring actors are screened and short-listed for future opportunities by leading casting directors</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              INTERACTION WITH CELEBRITIES
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={SaurabhShukla} alt='Saurabh Shukla 1'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={MukeshChhabra} alt='Mukesh Chhabra 2'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={RajatKapoor} alt='Rajat Kapoor 3'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={AnuragBasu} alt='Anurag Basu 4'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={MadhurBhandarkar} alt='Madhur Bhandarkar 5'/>
          </div>
          <div className='col-md-4 text-center my-2'>
            <img className='img-fluid' src={RajatKapur} alt='Rajat Kapur 6'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Post screening discussion and interaction of audience with Directors/Producers/Scriptwriters/Actors of the film.</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              PANEL DISCUSSIONS
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 text-center my-2'>
            <img className='img-fluid' src={Panel1} alt='Panel 1'/>
          </div>
          <div className='col-md-12 text-center my-2'>
            <img className='img-fluid' src={Panel2} alt='Panel 2'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Panel Discussions with Eminent film personalities.</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              MUSIC CONCERTS
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 text-center my-2'>
            <img className='img-fluid' src={Concert} alt='Concert'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Music concerts at the main stage on evenings to refresh the audience experiences.</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              FILM BAZAAR
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 text-center my-2'>
            <img className='img-fluid' src={FilmBazaar1} alt='Film Bazaar 1'/>
          </div>
          <div className='col-md-6 text-center my-2'>
            <img className='img-fluid' src={FilmBazaar2} alt='Film Bazaar 2'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>An interactive space for creative professionals to network and explore/form business alliances.</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              FOOD COURT
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 text-center my-2'>
            <img className='img-fluid' src={FoodCourt1} alt='Food Court 1'/>
          </div>
          <div className='col-md-6 text-center my-2'>
            <img className='img-fluid' src={FoodCourt2} alt='Food Court 2'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Comprising delicious traditional menu and other varieties.</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              GAMING EXPERIENCE ZONE
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 text-center my-2'>
            <img className='img-fluid' src={GamingZone} alt='Gaming Zone'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Provide an immersive and enjoyable gaming environment for gamers of all ages and skill levels</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              OPEN MIC
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 text-center my-2'>
            <img className='img-fluid' src={OpenMic} alt='Open Mic'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Where individuals, aften amateurs or aspiring artists can showcase their talents in front of an audience.</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center my-4'>
            <p className='display-6'>
              SELFIE CONTEST
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 text-center my-2'>
            <img className='img-fluid' src={Selfie} alt='Selfie'/>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 text-center'>
            <h4>Click selfie at selfie point and post on social media and tag us</h4>
          </div>
        </div>
        <div className='row my-4'>
          <div className='col-12'>
            <div className="d-grid gap-2">
              <MDBBtn className='landing-button' href='https://forms.gle/suLV872ZEYppJnWZ7'>Register Here</MDBBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home