// import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Home from './Components/Home';


function App() {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
